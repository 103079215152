import * as React from "react";
import { graphql } from "gatsby";
import Meta from "../../components/meta";
import Layout from "../../components/layout/Layout";
import Content from "../../components/layout/Content/Content";
import EnterpriseBanner from "../../components/pages/enterprise/EnterpriseBanner";
import EnterpriseFeatures from "../../components/pages/enterprise/EnterpriseFeatures";
import EnterpriseFormBlock from "../../components/pages/enterprise/EnterpriseFormBlock";


//WIP replace with new collaborateImageA
const Enterprise = ({ data }) => (
  <Layout>
    <Meta
      title="Uizard For Enterprise | Collaborative Wireframing | Uizard"
      description="Game-changing ideas can come from anyone in your organization. Give your team the power to express them efficiently. Read more online now."
      url="/enterprise/"
    />

    <Content>
      <EnterpriseBanner
        headline="Democratize design."
        description="Game-changing ideas can come from anyone in your organization.
          Give your team the power to express them efficiently."
        imageA={data.bannerImageA.childImageSharp}
        imageTopB={data.bannerTopImageB.childImageSharp}
        imageBottomB={data.bannerBottomImageB.childImageSharp}
      />
      <EnterpriseFeatures
        collaborateImageA={data.collaborateImageA.childImageSharp}
        componentsImageA={data.componentsImageA.childImageSharp}
        conversionImageA={data.conversionImageA.childImageSharp}
        teamsImageA={data.teamsImageA.childImageSharp}
        themesImageA={data.themesImageA.childImageSharp}
      />

      <EnterpriseFormBlock />
    </Content>
  </Layout>
);

export default Enterprise;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    bannerImageA: file(relativePath: { eq: "enterprise/banner-image-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 2074, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    bannerTopImageB: file(
      relativePath: { eq: "enterprise/banner-top-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 690, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    bannerBottomImageB: file(
      relativePath: { eq: "enterprise/banner-bottom-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 690, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    collaborateImageA: file(
      relativePath: { eq: "enterprise/collaborate-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    componentsImageA: file(
      relativePath: { eq: "enterprise/components-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1048, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    conversionImageA: file(
      relativePath: { eq: "enterprise/conversion-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1048, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    teamsImageA: file(relativePath: { eq: "enterprise/teams-image-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 864, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    themesImageA: file(relativePath: { eq: "enterprise/themes-image-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 864, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
