import * as React from "react";
import Styled from "styled-components/macro";
import Image, { GatsbyImageProps } from "gatsby-image";
import Media from "@uizard-io/styled-media-query";
import Headline from "../../../texts/Headline";
import Description from "../../../texts/Description";
import Button from "../../../buttons/Button";
import MediaQuery from "../../../layout/MediaQuery";
import TypeFormButton from 'components/buttons/TypeFormButton';


const Container = Styled.div`
  width: 100%;
  text-align: center;
  margin: 29rem 0 23.5rem 0;
  position: relative;
  padding: 0 14rem;
  
   ${Media.lessThan("1024px")`
      margin: 11rem 0 7rem 0;
  `}
  
  ${Media.lessThan("medium")`
      padding: 0 6rem;
  `}
  
  ${Media.lessThan("small")`
      padding: 0;
      margin: 9rem 0 4.5rem 0;
  `}
`;

const Collaborators = Styled.div`
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
`;

const ExtendedButton = Styled(Button)`
  height: 5rem;
  margin-top: 3rem;
  
  ${Media.lessThan("small")`
    margin-top: 1.5rem;
  `}
`;

const ImageA = Styled(Image)`
  width: calc(100% -  28rem);
  margin: 0 auto;
`;

const ImageBannerTopMobile = Styled(Image)`
  max-width: 32.5rem;
  margin: 0 auto 3rem auto;
 
`;

const ImageBannerBottomMobile = Styled(Image)`
  max-width: 32.5rem;
  margin: 3rem auto 0 auto;
`;

const ExtendedDescription = Styled(Description)`
    max-width: 70rem;
    margin: 0 auto;
    
   ${Media.lessThan("large")`
    max-width: 55rem;
  `}
`;

const NavButtonLink = Styled.a`
  margin-left: 2rem;
`;

const TypeFormButtonContainer = Styled(TypeFormButton)`
  background-color: transparent;
`;

type EnterpriseBannerProps = {
  headline: string;
  description: string;
  imageA: GatsbyImageProps;
  imageTopB: GatsbyImageProps;
  imageBottomB: GatsbyImageProps;
};

export default ({
  imageA,
  headline,
  description,
  imageTopB,
  imageBottomB,
}: EnterpriseBannerProps) => (
  <Container>
    <MediaQuery lessThan="1024px">
      <ImageBannerTopMobile
        fluid={"fluid" in imageTopB ? imageTopB.fluid : null}
        alt="Professional users' cursors and avatars in Uizard"
      />
    </MediaQuery>

    <Headline as="h1">{headline}</Headline>

    <ExtendedDescription>{description}</ExtendedDescription>

    <TypeFormButtonContainer id="WccWfRLY">
      <ExtendedButton type="submit" solid>
          Contact Sales
        </ExtendedButton>
    </TypeFormButtonContainer>

    <NavButtonLink
      href="https://calendly.com/rizwan-uizard/uizard-demo"
      target="_blank"
    >
      <ExtendedButton variant="black" solid={1}>
        Book a Demo
      </ExtendedButton>
    </NavButtonLink>

    <MediaQuery lessThan="1024px">
      <ImageBannerBottomMobile
        fluid={"fluid" in imageBottomB ? imageBottomB.fluid : null}
        alt="Professional users' cursors and avatars in Uizard"
      />
    </MediaQuery>

    <MediaQuery greaterThan="1024px">
      <Collaborators>
        <ImageA
          fluid={"fluid" in imageA ? imageA.fluid : null}
          alt="Professional users' cursors and avatars in Uizard"
        />
      </Collaborators>
    </MediaQuery>
  </Container>
);
