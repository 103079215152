import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Headline from "../../../texts/Headline";
import Description from "../../../texts/Description";
import EnterpriseForm from "../EnterpriseForm";
import EnterpriseFeatured from "../EnterpriseFeatured";

const Container = Styled.div`
  width: 100%;
  margin-top: 1rem;
  border-radius: 2rem;
  padding: 9rem 3rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.11);
  align-items: center;
  
   ${Media.lessThan("600px")`
    padding: 3rem;
  `}
`;

const ExtendedHeadline = Styled(Headline)`
  max-width: 45rem;
  font-size: 3.8rem;
  line-height: 4.5rem;
  text-align: center;
  margin-bottom: 5rem;
  
   ${Media.lessThan("medium")`
    font-size: 3rem;
    line-height: 3.6rem;
  `}
  
   ${Media.lessThan("small")`
    padding: 0 2rem;
  `}
`;

const ExtendedDescription = Styled(Description)`
  max-width: 71rem;
  text-align: center;
  margin-bottom: 3rem;
  font-size: 2.5rem;
  
   ${Media.lessThan("large")`
    max-width: 51rem;
  `}
`;

const EnterpriseFormBlock = () => (
  <Container id="contact_sales">
    <ExtendedDescription>
      Trusted by individuals and teams at the world's biggest companies
    </ExtendedDescription>
    <EnterpriseFeatured />
    <ExtendedHeadline>
      Get started with Uizard for enterprise today.
    </ExtendedHeadline>
    <EnterpriseForm />
  </Container>
);

export default EnterpriseFormBlock;
