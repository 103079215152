import * as React from "react";
import { Component } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";

import { getValidationErrors } from "../../../../helpers/validation";

import Form from "../../../form/Form";
import Label from "../../../form/Label";
import FormGroup from "../../../form/FormGroup";
import FormErrors from "../../../form/FormErrors";
import Input from "../../../form/Input";
import Button from "../../../buttons/Button";

const Container = Styled.div`
  width: 70.3rem;
  ${Media.lessThan("900px")`
    width: 50.3rem;
  `}
  
   ${Media.lessThan("600px")`
    width: 100%;
  `}
`;

const Headline = Styled.h3`
  font-size: 3.0rem;
  font-weight: 700;
  color: inherit;
  line-height: 1.0675;
  margin: 0 0 3rem 0;
 
`;

const Description = Styled.p`
  font-size: 1.6rem;
  font-weight: 400;
  color: inherit;
  line-height: 1.375;
  margin: 0 0 5rem 0;

  ${Media.greaterThan("medium")`
    font-size: 2rem;
  `}
`;

const Row = Styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  
   ${Media.lessThan("600px")`
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  `}
  
  ${FormGroup} { 
    width: calc(50% - 1.5rem);
    margin-bottom: 1.5rem;
    
   ${Media.lessThan("600px")`
    width: 100%;
  `}
 
  }
`;

const ExtendedButton = Styled(Button)`
  margin-top: 1.5rem;
  border-radius: 1rem;
  
  ${Media.lessThan("600px")`
    width: 100%;
    margin: 1.5rem auto 0 auto;
  `}
 
`;

const Success = Styled.div``;

class EnterpriseForm extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    loading: false,
    success: false,
    inputs: {
      firstName: "",
      lastName: "",
      email: "",
      companyName: "",
      companySize: "",
      jobTitle: "",
    },
    errors: {
      email: [],
    },
  };

  handleSubmit = async (e) => {
    try {
      e.preventDefault();

      // @ts-ignore
      const { salesRequest } = this.props;
      const { inputs } = this.state;

      this.setState({
        loading: true,
        errors: {
          subject: [],
          email: [],
          message: [],
        },
      });

      await salesRequest({
        firstName: inputs.firstName,
        lastName: inputs.lastName,
        email: inputs.email,
        companyName: inputs.companyName,
        companySize: inputs.companySize,
        jobTitle: inputs.jobTitle,
      });

      this.setState({
        loading: false,
        success: true,
      });
    } catch (error) {
      this.setState({
        loading: false,
        errors: {
          ...this.state.errors,
          ...getValidationErrors(error),
        },
      });
    }
  };

  handleInputChange = (e) => {
    this.setState({
      inputs: {
        ...this.state.inputs,
        [e.target.name]: e.target.value,
      },
    });
  };

  render() {
    const { loading, success, inputs, errors } = this.state;

    return (
      <Container>
        {!success && (
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <FormGroup>
                <Label>First Name</Label>
                <Input
                  type="text"
                  name="firstName"
                  value={inputs.firstName}
                  onChange={this.handleInputChange}
                  placeholder=""
                  backgrounded
                />
              </FormGroup>

              <FormGroup>
                <Label>Last Name</Label>
                <Input
                  type="text"
                  name="lastName"
                  value={inputs.lastName}
                  onChange={this.handleInputChange}
                  placeholder=""
                  backgrounded
                />
              </FormGroup>
            </Row>

            <Row>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  type="text"
                  name="email"
                  value={inputs.email}
                  onChange={this.handleInputChange}
                  placeholder=""
                  backgrounded
                />
                <FormErrors errors={errors.email} />
              </FormGroup>

              <FormGroup>
                <Label>Company Name</Label>
                <Input
                  type="text"
                  name="companyName"
                  value={inputs.companyName}
                  onChange={this.handleInputChange}
                  placeholder=""
                  backgrounded
                />
              </FormGroup>
            </Row>

            <Row>
              <FormGroup>
                <Label>Company Size</Label>
                <Input
                  type="text"
                  name="companySize"
                  value={inputs.companySize}
                  onChange={this.handleInputChange}
                  placeholder=""
                  backgrounded
                />
              </FormGroup>

              <FormGroup>
                <Label>Job Title</Label>
                <Input
                  type="text"
                  name="jobTitle"
                  value={inputs.jobTitle}
                  onChange={this.handleInputChange}
                  placeholder=""
                  backgrounded
                />
              </FormGroup>
            </Row>

            {/*
                  <Row>
                    <FormGroup>
                      <Label>Country Code</Label>
                      <Input
                        type="text"
                        name="countryCode"
                        value={inputs.countryCode}
                        onChange={this.handleInputChange}
                        placeholder=""
                        backgrounded
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>Phone Number</Label>
                      <Input
                        type="text"
                        name="phoneNumber"
                        value={inputs.phoneNumber}
                        onChange={this.handleInputChange}
                        placeholder=""
                        backgrounded
                      />
                    </FormGroup>
                  </Row>
                  */}

            <ExtendedButton
              type="submit"
              expand
              loading={loading}
              variant="primarylight"
              solid
            >
              Contact Sales
            </ExtendedButton>
          </Form>
        )}

        {success && (
          <Success>
            <Headline>Thank you</Headline>
            <Description>We have received your message.</Description>
          </Success>
        )}
      </Container>
    );
  }
}

const salesRequest = graphql(
  gql`
    mutation SalesRequest(
      $firstName: String!
      $lastName: String!
      $email: String!
      $companyName: String!
      $companySize: String!
      $jobTitle: String!
    ) {
      salesRequest(
        firstName: $firstName
        lastName: $lastName
        email: $email
        companyName: $companyName
        companySize: $companySize
        jobTitle: $jobTitle
      ) {
        mutationId
      }
    }
  `,
  {
    props({ mutate }) {
      return {
        salesRequest(variables) {
          return mutate({ variables });
        },
      };
    },
  }
);

export default compose(salesRequest)(EnterpriseForm);
