import * as React from "react";
import { Component } from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";

import Content from "../../../layout/Content/Content";

import logoFacebook from "./logo-facebook.png";
import logoCapitalOne from "./logo-capital-one.png";
import logoCognizant from "./logo-cognizant.png";
import logoHitachiSolutions from "./logo-hitachi-solutions.png";
import logoExperian from "./logo-experian.png";
import logoNTTData from "./logo-ntt-data.png";

const Articles = Styled.div`
  text-align: center;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 0 1rem 0;

  ${Media.greaterThan("medium")`
    width: 60rem;
    margin: 0 0 5rem 0;
  `}

  ${Media.greaterThan("large")`
    width: 100%;
  `}
`;

const Article = Styled.div`
  margin: 0 1.25rem 3rem 1.25rem; 
  text-align: center;

  ${Media.greaterThan("medium")`
    margin: 0 2.5rem 3rem 2.5rem;
  `}
`;

const Logo = Styled.img`
  display: block;
  height: 2rem;

  ${Media.greaterThan("medium")`
    height: 3rem;
  `}
`;

class EnterpriseFeatured extends Component {
  state = {
    articles: [
      {
        image: logoCapitalOne,
        alt: "Capital One",
      },
      {
        image: logoCognizant,
        alt: "Cognizant",
      },
      {
        image: logoHitachiSolutions,
        alt: "Hitachi Solutions",
      },
      {
        image: logoExperian,
        alt: "Experian",
      },
      {
        image: logoFacebook,
        alt: "Facebook",
      },
      {
        image: logoNTTData,
        alt: "NTT Data",
      },
    ],
  };

  render() {
    return (
      <Articles>
        {this.state.articles.map((article) => (
          <Article key={article.alt}>
            <Logo src={article.image} alt={article.alt} />
          </Article>
        ))}
      </Articles>
    );
  }
}

export default EnterpriseFeatured;
