import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import { GatsbyImageProps } from "gatsby-image";
import BasicCard, {
  BasicCardContainer,
  ExtendedDescription,
  ExtendedHeadline,
  ImageA,
} from "../../../cards/BasicCard";
import Headline from "../../../texts/Headline";
import Button from "../../../buttons/Button";
import Description from "../../../texts/Description";
import { HybridLink } from "../../../layout/Footer";

const ExtendedLink = Styled(HybridLink)``;

const Rows = Styled.div`
  display: flex;
  flex-direction: column;
  
  ${Media.lessThan("600px")`
    flex-direction: column;
  `} 
  
  ${ExtendedHeadline}, ${ExtendedDescription} {
    padding-right: 9rem;
    
    ${Media.lessThan("1024px")`
        padding-right: 3rem;
    `} 
  }
  
  ${BasicCardContainer} {
    justify-content: center;
    display: flex;
    flex-direction: column;
    
    ${Media.lessThan("600px")`
    width: 100%;
  `}
  }
`;

const QuestionCard = Styled.div`
  background-color: black;
  border-radius: 2rem;
  color: white;
  padding: 9rem;
  width: 100%;
  text-align: ${(props) => props.center && "center"};
  
  ${Media.lessThan("1024px")`
        padding: 3rem;
  `} 
`;

const QuestionCardHeadline = Styled(Headline)`
  font-size: 3.8rem;
  line-height: 3.3rem;
  
  ${Media.lessThan("1024px")`
    font-size: 3rem;
    line-height: 3.6rem;
  `} 
`;

const ButtonExtended = Styled(Button)`
  font-size: 1.6rem;
  color: white;
  border-radius: 1rem;
`;

const Column = Styled.div`
  width: ${(props) => props.width && `calc(${props.width} - 1.5rem)`};
  ${Media.lessThan("600px")`
            width: 100%;
  `}
`;

const Row = Styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  text-align: ${(props) => (props.center ? "center" : "left")};
  
  & > * {
    margin-bottom: 3rem;
  }
 
  ${QuestionCard} {
    width: ${(props) =>
      props.widthQuestionCard
        ? `calc(${props.widthQuestionCard} - 1.5rem)`
        : "100%"};
    
    ${Media.lessThan("600px")`
            width: 100%;
            text-align: center;
    `}
  }
  
  ${BasicCardContainer} {
    text-align: ${(props) => props.image && "center"};
    
    ${(props) =>
      props.widthLeftCard === "100%" &&
      !props.noCenter &&
      `
      align-items: center;
   
    ${Headline}, ${Description} {
      padding: 0;
    }
    
    ${ImageA} {
     
    }
  `}
  
   ${Media.lessThan("600px")`
     ${(props) =>
       props.leftMarginBottom &&
       `
        ${Description} {
           margin-bottom: 3rem;
        }
    `}
  `}
  
  ${Media.lessThan("small")`
     ${(props) =>
       props.widthLeftCard === "100%" &&
       !props.noCenter &&
       `
        ${Description} {
           padding: 0 3rem;
        }
    `}
  `}
  
  ${Description} {
    margin-bottom: ${(props) => props.noCenter && "0"};
  }
  
    &:first-of-type {
      ${Media.lessThan("600px")`
          width: 100%;
      `}
      
      // eslint-disable-next-line no-nested-ternary
      padding: ${(props) => props.noCenter && "9rem 0 9rem 9rem"};
        
      ${Media.lessThan("1024px")`
          padding: ${(props) => props.noCenter && "3rem 0 3rem 3rem"};
      `} 
    
      width: ${(props) =>
        props.widthLeftCard === "100%"
          ? "100%"
          : props.widthLeftCard && !props.noCenter
          ? `calc(${props.widthLeftCard} - 1.5rem)`
          : props.noCenter
          ? "100%"
          : "calc(50% - 1.5rem)"};
    }
    
    &:nth-of-type(2) {
      ${Media.lessThan("600px")`
          width: 100%;
      `}
      
      width: ${(props) =>
        props.widthRightCard && !props.noCenter
          ? `calc(${props.widthRightCard} - 1.5rem)`
          : "calc(50% - 1.5rem)"};
    }

  }

  
  ${Media.lessThan("600px")`
    width: 100%;
    flex-direction: column;
  `}
`;

type EnterpriseFeaturesProps = {
  collaborateImageA: GatsbyImageProps;
  componentsImageA: GatsbyImageProps;
  conversionImageA: GatsbyImageProps;
  teamsImageA: GatsbyImageProps;
  themesImageA: GatsbyImageProps;
};

const EnterpriseFeatures = ({
  collaborateImageA,
  componentsImageA,
  conversionImageA,
  teamsImageA,
  themesImageA,
}: EnterpriseFeaturesProps) => (
  <Rows>
    <Row widthLeftCard="100%" center>
      <BasicCard
        centered
        icon="support"
        image={{
          heightDesktop: "465px",
          heightMob: "222px",
          imageA: collaborateImageA,
        }}
        headline="Collaborate"
        description="Collaborate live with your entire team wherever they are — all from the comfort of your web browser. Create in real-time, write comments, share feedback, and iterate quickly and easily."
      />
    </Row>

    <Row widthLeftCard="50%" widthRightCard="50%">
      <BasicCard
        icon="team"
        image={{
          heightDesktop: "36rem",
          heightMob: "20.4rem",
          imageA: teamsImageA,
        }}
        headline="Team management"
        description="Assign organization-wide roles and permissions. Manage creator and viewer seats. Set projects as public or private. With Uizard, you're always sure who can do what."
      />

      <BasicCard
        icon="brand"
        image={{
          heightDesktop: "36rem",
          heightMob: "20.4rem",
          imageA: themesImageA,
        }}
        headline="Brand consistency"
        description="Share themes, fonts, styles, components, and templates across your entire organization to ensure consistency and efficiency across all your projects."
      />
    </Row>

    <Row>
      <QuestionCard center>
        <QuestionCardHeadline>
          How is Uizard different from other solutions?
        </QuestionCardHeadline>
        <ExtendedLink to="/uizard-vs-design-tools/">
          <ButtonExtended type="submit" variant="primarylight" solid>
            Read more here
          </ButtonExtended>
        </ExtendedLink>
      </QuestionCard>
    </Row>

    <Row leftMarginBottom widthLeftCard="50%" widthRightCard="50%">
      <BasicCard
        icon="transform"
        image={{
          heightDesktop: "36rem",
          heightMob: "20.4rem",
          imageA: conversionImageA,
        }}
        headline="Turn brainstorming sessions into tangible deliverables"
        description="With Uizard, you can automatically transform sketches and wireframes into interactive prototypes in minutes."
      />

      <BasicCard
        icon="component"
        image={{
          heightDesktop: "36rem",
          heightMob: "20.4rem",
          imageA: componentsImageA,
        }}
        headline="Refresh your brand design in minutes"
        description="With Uizard, you can automatically create themes and components from your company's brand identity, style guide, and design system."
      />
    </Row>

    <Row>
      <QuestionCard center>
        <QuestionCardHeadline>
          How is Uizard powered by AI?
        </QuestionCardHeadline>
        <ExtendedLink to="/design-assistant/">
          <ButtonExtended type="submit" variant="primarylight" solid>
            Read more here
          </ButtonExtended>
        </ExtendedLink>
      </QuestionCard>
    </Row>
  </Rows>
);

export default EnterpriseFeatures;
